import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const useTransactionScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [amount, setAmount] = useState("");
    const [selectedTab, setSelectedTab] = useState("Expense");
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/login");
        }
    }, [navigate]);

    const handleSwitch = (tab) => {
        setSelectedTab(tab);
    };

    const handleButtonClick = async () => {
        if (!selectedCategory) {
            setNotification({ message: t("no_category_selected"), type: "error" });
            return;
        }

        if (!amount || isNaN(amount)) {
            setNotification({ message: t("invalid_amount"), type: "error" });
            return;
        }

        const transactionData = {
            amount: parseFloat(amount),
            type: selectedTab.toLowerCase(),
            category: {
                id: selectedCategory.id,
                name: selectedCategory.key,
                icon: selectedCategory.icon,
            },
        };

        try {
            const token = localStorage.getItem("token");
            if (!token) {
                setNotification({ message: t("no_authentication_token"), type: "error" });
                return;
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transactions/add`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(transactionData),
            });

            if (response.ok) {
                const data = await response.json();
                setNotification({ message: t("transaction_saved_successfully"), type: "success" });
                console.log("Transaction response:", data);

                setAmount("");
                setSelectedTab("Expense");
                setSelectedCategory(null);
            } else {
                const errorData = await response.json();
                setNotification({
                    message: `${t("failed_to_save_transaction")}: ${errorData.message || t("unknown_error")}`,
                    type: "error",
                });
                console.error("Error response:", errorData);
            }
        } catch (error) {
            setNotification({ message: t("network_error"), type: "error" });
            console.error("Network error:", error);
        }
    };

    return {
        t,
        amount,
        setAmount,
        selectedTab,
        setSelectedTab,
        selectedCategory,
        setSelectedCategory,
        notification,
        setNotification,
        handleSwitch,
        handleButtonClick,
    };
};
