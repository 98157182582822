import React from 'react';
import { useUserName } from './TopMenuScript';
import "./TopMenuMB.css";

const TopMenuMB = () => {
    const token = localStorage.getItem('token');
    const { userName, loading, error } = useUserName(token);
    const userImage = "/user-icon.png";

    return (
        <div className="top-menu-container-mb">
            <div className="top-menu-left-section-mb">
                <img src={userImage} alt="User" className="top-menu-user-image" />
                <span className="top-menu-user-name">
                    {loading ? 'Loading...' : error ? 'Error' : userName}
                </span>
            </div>
        </div>
    );
};

export default TopMenuMB;
