export const handleRegister = async (name, email, password, navigate, setErrorMessage, t) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/user/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password, name }),
        });

        const data = await response.json();

        if (!response.ok) {
            setErrorMessage(t('invalid_register_credentials'));
            return;
        }

        if (data.token) {
            localStorage.setItem('token', data.token);
            navigate('/home');
        } else {
            setErrorMessage(t('unexpected_error'));
        }
    } catch (error) {
        setErrorMessage(t('network_error'));
    }
};
