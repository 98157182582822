import React, { useEffect } from "react";
import BalanceCardMB from "../../components/balanceCard/BalanceCardMB";
import "./DashboardScreenMB.css";
import StatisticsChart from "../../components/chart/bar/StatisticsChart";
import TransactionList from "../../components/transactionList/TransactionList";
import { useDashboardScreen } from "./DashboardScreenScripts";

function DashboardScreenMB() {
    useDashboardScreen();

    return (
        <div className="dashboard-screen-mb-container">
            <BalanceCardMB />

            <StatisticsChart />

            <TransactionList />
        </div>
    );
}

export default DashboardScreenMB;
