import React from "react";
import { FiLogOut, FiInfo } from "react-icons/fi";
import LogOutModal from "../../components/modal/LogOutModal";
import { useProfileScreen } from "./ProfileScreenScript";
import "./ProfileScreenMB.css";
import {useTranslation} from 'react-i18next';

function ProfileScreenMB() {
    const {
        activeItem,
        showModal,
        handleItemClick,
        handleConfirmLogout,
        handleCloseModal,
    } = useProfileScreen();
    const {t} = useTranslation();

    return (
        <div className="profile-items-container-mb">
            <div
                className={`profile-item-mb ${activeItem === "log-out" ? "active" : ""}`}
                onClick={() => handleItemClick("log-out")}
            >
                <div className="menu-item-left-mb">
                    <FiLogOut className="menu-item-icon-mb" />
                    <span className="menu-item-text-mb">{t('log_out')}</span>
                </div>
                <FiInfo className="menu-item-icon-mb menu-item-info-icon-mb" />
            </div>

            {showModal && (
                <LogOutModal
                    message={t('confirm_log_out')}
                    onClose={handleCloseModal}
                    onConfirm={handleConfirmLogout}
                />
            )}
        </div>
    );
}

export default ProfileScreenMB;
