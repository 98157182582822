import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";

export const LoginScreenScript = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/home');
        }
    }, [navigate]);

    const handleLogin = async () => {
        try {
            const response =
                await fetch(`${process.env.REACT_APP_API_URL}/api/user/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email, password}),
            });

            const data = await response.json();

            if (!response.ok) {
                setErrorMessage(t('invalid_login_credentials'));
                return;
            }

            if (data.token) {
                localStorage.setItem('token', data.token);
                navigate('/home');
            } else {
                setErrorMessage('An unexpected error occurred.');
            }
        } catch (error) {
            setErrorMessage('Network error occurred.');
        }
    };

    return {
        email,
        setEmail,
        password,
        setPassword,
        errorMessage,
        setErrorMessage,
        handleLogin,
    };
};
