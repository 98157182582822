import { useState, useEffect } from 'react';

export const useBalance = (token) => {
    const [balance, setBalance] = useState({ total_income: 0, total_expense: 0, net_total: 0 });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBalance = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transactions/total`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    setBalance({
                        total_income: parseFloat(data.total_income),
                        total_expense: parseFloat(data.total_expense),
                        net_total: parseFloat(data.net_total),
                    });
                } else {
                    setError(data.error || 'Failed to fetch balance data');
                }
            } catch (error) {
                setError('Network error occurred');
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchBalance();
        }
    }, [token]);

    return { balance, loading, error };
};
