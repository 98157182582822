import React from "react";
import "./TransactionScreenMB.css";
import IncomeExpenseSwitcher from "../../components/IncomeExpenseSwitcher/IncomeExpenseSwitcher";
import InputOne from "../../components/input/one/InputOne";
import ButtonOne from "../../components/buttons/one/ButtonOne";
import TransactionType from "../../components/transactionType/TransactionType";
import NotificationModal from "../../components/modal/NotificationModal";
import { useTransactionScreen } from "./TransactionScreenScripts";

function TransactionScreenMB() {
    const {
        t,
        amount,
        setAmount,
        selectedTab,
        setSelectedTab,
        selectedCategory,
        setSelectedCategory,
        notification,
        setNotification,
        handleSwitch,
        handleButtonClick,
    } = useTransactionScreen();

    return (
        <div className="transaction-screen-container-mb">
            {notification && (
                <NotificationModal
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}

            <IncomeExpenseSwitcher onSwitch={handleSwitch} />

            <div className="input-amount-container-mb">
                <div className="input-amount-mb">
                    <InputOne
                        icon="faMoneyBill"
                        label={t("enter_amount")}
                        value={amount}
                        type="number"
                        onChange={(e) => setAmount(e.target.value)}
                    />
                </div>
            </div>

            <TransactionType onSelectCategory={setSelectedCategory} />

            <div className="input-amount-button-mb">
                <ButtonOne title={t("add")} onClick={handleButtonClick} />
            </div>
        </div>
    );
}

export default TransactionScreenMB;
