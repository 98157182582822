import React from 'react';
import './ButtonOne.css';

function ButtonOne({ title, onClick }) {
    return (
        <button
            className="buttonOne"
            onClick={onClick}
        >
            {title}
        </button>
    );
}

export default ButtonOne;
