import React, { useState, useEffect } from "react";
import "./TransactionList.css";
import DeleteConfirmationModal from "../modal/DeleteConfirmationModal";
import { useTranslation } from "react-i18next";

function TransactionList() {
    const [transactions, setTransactions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [transactionToDelete, setTransactionToDelete] = useState(null);
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        fetchTransactions();
    }, []);

    const fetchTransactions = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            setError("User not authenticated. Please log in.");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transactions/list`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to fetch transactions");
            }

            const data = await response.json();
            setTransactions(data);
        } catch (err) {
            setError("Failed to load transactions. Please try again later.");
            console.error(err);
        }
    };

    const handleDeleteClick = (id) => {
        setTransactionToDelete(id);
        setShowModal(true);
    };

    const handleConfirmDelete = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            setError("User not authenticated. Please log in.");
            setShowModal(false);
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transactions/delete`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ transaction_id: transactionToDelete }),
            });

            if (!response.ok) {
                throw new Error("Failed to delete transaction");
            }

            setTransactions((prev) =>
                prev.filter((transaction) => transaction.id !== transactionToDelete)
            );
            setTransactionToDelete(null);
            setShowModal(false);
        } catch (err) {
            setError("Failed to delete transaction. Please try again later.");
            console.error(err);
            setShowModal(false);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setTransactionToDelete(null);
    };

    return (
        <div className="transaction-list-container">
            {error && <div className="error-message">{error}</div>}
            <ul className="transaction-list">
                {transactions.map((transaction) => (
                    <li key={transaction.id} className="transaction-item">
                        <div className="transaction-left">
                            <span className="transaction-icon">{transaction.icon}</span>
                            <div>
                                <span className="transaction-type">{t(transaction.category_name)}</span>
                                <span
                                    className={`transaction-category ${
                                        transaction.type === "income"
                                            ? "category-income"
                                            : "category-expense"
                                    }`}
                                >
                                    {t(transaction.type)}
                                </span>
                            </div>
                        </div>
                        <div className="transaction-right">
                            <div className="transaction-amount">
                                MDL-{!isNaN(Number(transaction.amount)) ? Number(transaction.amount).toFixed(2) : "0.00"}
                            </div>
                            <div className="transaction-date">{new Date(transaction.created_at).toLocaleDateString()}</div>
                        </div>
                        <div
                            className="transaction-delete"
                            onClick={() => handleDeleteClick(transaction.id)}
                        >
                            <svg
                                className="delete-icon"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                width="20"
                                height="20"
                            >
                                <title>{`Delete transaction with ID: ${transaction.id}`}</title>
                                <path
                                    d="M9 3V4H4V6H5V19C5 20.1 5.9 21 7 21H17C18.1 21 19 20.1 19 19V6H20V4H15V3H9ZM7 6H17V19H7V6ZM9 8V17H11V8H9ZM13 8V17H15V8H13Z"
                                />
                            </svg>
                        </div>
                    </li>
                ))}
            </ul>
            {showModal && (
                <DeleteConfirmationModal
                    message={t("delete_transaction")}
                    onClose={handleCloseModal}
                    onConfirm={handleConfirmDelete}
                />
            )}
        </div>
    );
}

export default TransactionList;
