import React, {useEffect} from 'react';
import './Modal.css';
import {useTranslation} from "react-i18next";

function LoginRegisterModal({message, onClose}) {
    const {t} = useTranslation();

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 3000);

        return () => clearTimeout(timeoutId);
    }, [onClose]);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget && onClose) {
            onClose();
        }
    };

    return (
        <div className="modalOverlay" onClick={handleOverlayClick}>
            <div className="modalContent">
                <p className="modalTitle">{t('ops')}</p>
                <p className="modalMessage">{message}</p>
                <button onClick={onClose} className="modalButton">
                    {t('cancel')}
                </button>
            </div>
        </div>
    );
}

export default LoginRegisterModal;
