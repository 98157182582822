import React, {useEffect} from 'react';
import {Route, Routes, useLocation, useNavigate, Navigate} from 'react-router-dom';
import {isMobile} from 'react-device-detect';
import './i18n';
import HomeScreenPC from "./screens/home/HomeScreenPC";
import HomeScreenMB from "./screens/home/HomeScreenMB";
import LoginScreenMB from "./screens/login/LoginScreenMB";
import LoginScreenPC from "./screens/login/LoginScreenPC";
import RegisterScreenPC from "./screens/register/RegisterScreenPC";
import RegisterScreenMB from "./screens/register/RegisterScreenMB";
import DashboardScreenMB from "./screens/dashboard/DashboardScreenMB";
import DashboardScreenPC from "./screens/dashboard/DashboardScreenPC";
import ProfileScreenMB from "./screens/profile/ProfileScreenMB";
import ProfileScreenPC from "./screens/profile/ProfileScreenPC";
import TransactionScreenPC from "./screens/transaction/TransactionScreenPC";
import TransactionScreenMB from "./screens/transaction/TransactionScreenMB";

function App() {
    const location = useLocation();

    useEffect(() => {
        initializeTheme();
    }, []);

    const LoginScreen = isMobile ? LoginScreenMB : LoginScreenPC;
    const RegisterScreen = isMobile ? RegisterScreenMB : RegisterScreenPC;
    const HomeScreen = isMobile ? HomeScreenMB : HomeScreenPC;
    const DashboardScreen = isMobile ? DashboardScreenMB : DashboardScreenPC;
    const ProfileScreen = isMobile ? ProfileScreenMB : ProfileScreenPC;
    const TransactionScreen = isMobile ? TransactionScreenMB : TransactionScreenPC;

    return (
        <Routes location={location} key={location.pathname}>
            <Route path="/" element={<AuthRedirect/>}/>
            <Route path="/login" element={<LoginScreen/>}/>
            <Route path="/register" element={<RegisterScreen/>}/>
            <Route path="/home" element={<HomeScreen/>}>
                <Route index element={<Navigate to="dashboard"/>}/>
                <Route path="dashboard" element={<DashboardScreen/>}/>
                <Route path="profile" element={<ProfileScreen/>}/>
                <Route path="add" element={<TransactionScreen/>}/>
            </Route>
        </Routes>
    );
}

function AuthRedirect() {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            navigate('/home');
        } else {
            navigate('/login');
        }
    }, [navigate]);
}

export default App;

export function applyTheme(theme) {
    const root = document.documentElement;
    const appRoot = document.getElementById('root');
    const body = document.body;

    if (theme === 'dark') {
        body.style.backgroundColor = '#000000';
    } else {
        body.style.backgroundColor = '#FFFFFF';
    }

    if (theme === 'dark') {
        root.style.setProperty('--color6', 'rgba(0, 0, 0, 1)');
    } else {
        root.style.setProperty('--color6', 'rgb(255,255,255)');
    }

    if (theme === 'dark') {
        root.style.setProperty('--color0', 'rgb(255,255,255)');
    } else {
        root.style.setProperty('--color0', 'rgba(0, 0, 0, 1)');
    }

    if (theme === 'dark') {
        root.style.setProperty('--color4', 'rgba(62, 180, 137, 1)');

    } else {
        root.style.setProperty('--color4', 'rgba(62, 180, 137, 1)');
    }

    if (theme === 'dark') {

    } else {

    }

        if (theme === 'dark') {
        root.style.setProperty('--color2', 'rgba(28, 28, 30, 1)');
        root.style.setProperty('--color7', 'rgba(28, 28, 30, 0.7)');
        root.style.setProperty('--color1', 'rgba(235, 235, 245, 0.5)');
        root.style.setProperty('--color8', 'rgb(255,255,255, 0.1)');
        root.style.setProperty('--color3', 'rgba(31, 90, 68, 1)');
        root.style.setProperty('--color5', 'rgba(62, 180, 137, 0.4)');
        root.style.setProperty('--color9', 'rgba(35, 98, 91, 1)');
        } else {
        root.style.setProperty('--color2', 'rgba(242, 242, 247, 1)');
        root.style.setProperty('--color7', 'rgba(242, 242, 247, 0.7)');
        root.style.setProperty('--color1', 'rgba(60, 60, 67, 0.5)');
        root.style.setProperty('--color8', 'rgba(0, 0, 0, 0.1)');
        root.style.setProperty('--color3', 'rgba(31, 90, 68, 1)');
        root.style.setProperty('--color5', 'rgba(62, 180, 137, 0.6)');
        root.style.setProperty('--color9', 'rgba(35, 98, 91, 1)');
        }

    root.style.setProperty('width', '100%');
    root.style.setProperty('height', '100%');

    if (body) {
        body.style.width = '100%';
        body.style.height = '100%';
        body.style.margin = '0';
        body.style.padding = '0';
        body.style.overflow = 'hidden';
    }

    if (appRoot) {
        appRoot.style.width = '100%';
        appRoot.style.height = '100%';
    }

    localStorage.setItem('theme', theme);
}

export function toggleTheme() {
    const currentTheme = localStorage.getItem('theme') || 'light';
    const newTheme = currentTheme === 'light' ? 'dark' : 'light';
    applyTheme(newTheme);
}

export function initializeTheme() {
    const savedTheme = localStorage.getItem('theme');

    if (savedTheme) {
        applyTheme(savedTheme);
    } else {
        const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        applyTheme(systemTheme);
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
        const newSystemTheme = e.matches ? 'dark' : 'light';
        applyTheme(newSystemTheme);
    });
}
