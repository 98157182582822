import React from "react";
import {Outlet} from "react-router-dom";
import BottomMenuPC from "../../components/menu/bottom/BottomMenuPC";
import TopMenuPC from "../../components/menu/top/TopMenuPC";
import "./HomeScreenPC.css";

function HomeScreenPC() {
    return (
        <div className="homeScreenContainer-pc">
            <TopMenuPC/>

            <div className="homeScreenMainContent-pc">
                <BottomMenuPC/>

                <div className="homeScreenContentArea-pc">
                    <Outlet/>
                </div>
            </div>
        </div>
    );
}

export default HomeScreenPC;
