import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useProfileScreen = () => {
    const [activeItem, setActiveItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/login");
        }
    }, [navigate]);

    const handleItemClick = (item) => {
        setActiveItem(item);
        if (item === "log-out") {
            setShowModal(true);
        }
    };

    const handleConfirmLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return {
        activeItem,
        setActiveItem,
        showModal,
        setShowModal,
        handleItemClick,
        handleConfirmLogout,
        handleCloseModal,
    };
};
