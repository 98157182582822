import React from "react";
import {useBalance} from "./BalanceCardScript";
import "./BalanceCardPC.css";
import {useTranslation} from 'react-i18next';

const BalanceCardPC = () => {
    const token = localStorage.getItem('token');
    const {balance, loading, error} = useBalance(token);
    const {t} = useTranslation();

    return (
        <div className="balance-card-pc">
            <div className="background-shape-1-pc"></div>
            <div className="background-shape-2-pc"></div>
            <div className="background-shape-3-pc"></div>

            <div className="balance-card-content-pc">
                <p className="card-title-pc">{t('total_balance')}</p>
                <div className="balance-amount-pc">
                    <h1>
                        {loading
                            ? t('loading')
                            : error
                                ? t('error')
                                : balance.net_total.toLocaleString('en-US')} MDL
                    </h1>
                </div>
            </div>
        </div>
    );
};

export default BalanceCardPC;
