import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './IncomeExpenseSwitcher.css';

const IncomeExpenseSwitcher = ({ onSwitch }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('Expense');

    const handleSwitch = (tab) => {
        setActiveTab(tab);
        onSwitch(tab);
    };

    return (
        <div className="switcher-container-mb">
            <button
                className={`switcher-tab-mb ${activeTab === 'Income' ? 'active' : ''}`}
                onClick={() => handleSwitch('Income')}
            >
                {t('income')}
            </button>
            <button
                className={`switcher-tab-mb ${activeTab === 'Expense' ? 'active' : ''}`}
                onClick={() => handleSwitch('Expense')}
            >
                {t('expense')}
            </button>
            <div className={`highlight-mb ${activeTab}`} />
        </div>
    );
};

export default IncomeExpenseSwitcher;
