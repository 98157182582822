import React, { useState, useEffect } from "react";
import "./TransactionType.css";
import { useTranslation } from "react-i18next";

function TransactionType({ onSelectCategory }) {
    const [selectedType, setSelectedType] = useState(null);
    const [transactionTypes, setTransactionTypes] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchPriorities = async () => {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error("No token found in localStorage.");
                    return;
                }

                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/transactions/categories/count`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const data = await response.json();

                const updatedTransactionTypes = initialTransactionTypes.map((type) => {
                    const match = data.find((item) => item.category_id === type.id.toString());
                    return {
                        ...type,
                        priority: match ? match.total : 0,
                    };
                });

                setTransactionTypes(updatedTransactionTypes.sort((a, b) => b.priority - a.priority));
            } catch (error) {
                console.error("Error fetching category priorities:", error);
            }
        };

        fetchPriorities();
    }, []);

    const initialTransactionTypes = [
        {id: 1, icon: "🍴", key: "dining_cooking", name: t('dining_cooking'), description: t('dining_cooking_description'), priority: 0},
        {id: 2, icon: "🍽️", key: "restaurant", name: t('restaurant'), description: t('restaurant_description'), priority: 0},
        {id: 3, icon: "☕", key: "cafe", name: t('cafe'), description: t('cafe_description'), priority: 0},
        {id: 4, icon: "🍫", key: "sweets", name: t('sweets'), description: t('sweets_description'), priority: 0},
        {id: 5, icon: "🍹", key: "drinks", name: t('drinks'), description: t('drinks_description'), priority: 0},
        {id: 6, icon: "🚌", key: "public_transport", name: t('public_transport'), description: t('public_transport_description'), priority: 0},
        {id: 7, icon: "🚕", key: "taxi_transport", name: t('taxi_transport'), description: t('taxi_transport_description'), priority: 0},
        {id: 8, icon: "🎥", key: "cinema", name: t('cinema'), description: t('cinema_description'), priority: 0},
        {id: 9, icon: "🏋️‍♂️", key: "gym", name: t('gym'), description: t('gym_description'), priority: 0},
        {id: 10, icon: "🥊", key: "boxing", name: t('boxing'), description: t('boxing_description'), priority: 0},
        {id: 11, icon: "🏊", key: "swimming", name: t('swimming'), description: t('swimming_description'), priority: 0},
        {id: 12, icon: "💻", key: "office_work", name: t('office_work'), description: t('office_work_description'), priority: 0},
        {id: 13, icon: "🎁", key: "gifts", name: t('gifts'), description: t('gifts_description'), priority: 0},
        {id: 14, icon: "💖", key: "love_dating", name: t('love_dating'), description: t('love_dating_description'), priority: 0},
        {id: 15, icon: "🏥", key: "health_medicine", name: t('health_medicine'), description: t('health_medicine_description'), priority: 0},
        {id: 16, icon: "🚗", key: "personal_car", name: t('personal_car'), description: t('personal_car_description'), priority: 0},
        {id: 17, icon: "🛍️", key: "shopping", name: t('shopping'), description: t('shopping_description'), priority: 0},
        {id: 18, icon: "📚", key: "learning", name: t('learning'), description: t('learning_description'), priority: 0},
        {id: 19, icon: "🏠", key: "home_care", name: t('home_care'), description: t('home_care_description'), priority: 0},
        {id: 20, icon: "💡", key: "utilities", name: t('utilities'), description: t('utilities_description'), priority: 0},
        {id: 21, icon: "💳", key: "loans", name: t('loans'), description: t('loans_description'), priority: 0},
        {id: 22, icon: "🎶", key: "music", name: t('music'), description: t('music_description'), priority: 0},
        {id: 23, icon: "🎨", key: "art", name: t('art'), description: t('art_description'), priority: 0},
        {id: 24, icon: "📷", key: "photography", name: t('photography'), description: t('photography_description'), priority: 0},
        {id: 25, icon: "✈️", key: "travel", name: t('travel'), description: t('travel_description'), priority: 0},
        {id: 26, icon: "🌴", key: "vacation", name: t('vacation'), description: t('vacation_description'), priority: 0},
        {id: 27, icon: "🐾", key: "pets", name: t('pets'), description: t('pets_description'), priority: 0},
        {id: 28, icon: "🍎", key: "groceries", name: t('groceries'), description: t('groceries_description'), priority: 0},
        {id: 29, icon: "👕", key: "clothing", name: t('clothing'), description: t('clothing_description'), priority: 0},
        {id: 30, icon: "🖥️", key: "electronics", name: t('electronics'), description: t('electronics_description'), priority: 0},
        {id: 31, icon: "⚽", key: "sports", name: t('sports'), description: t('sports_description'), priority: 0},
        {id: 32, icon: "🏫", key: "education", name: t('education'), description: t('education_description'), priority: 0},
        {id: 33, icon: "📖", key: "books", name: t('books'), description: t('books_description'), priority: 0},
        {id: 34, icon: "🌐", key: "internet", name: t('internet'), description: t('internet_description'), priority: 0},
        {id: 35, icon: "🏢", key: "rent", name: t('rent'), description: t('rent_description'), priority: 0},
        {id: 36, icon: "🪴", key: "gardening", name: t('gardening'), description: t('gardening_description'), priority: 0},
        {id: 37, icon: "🎮", key: "gaming", name: t('gaming'), description: t('gaming_description'), priority: 0},
        {id: 38, icon: "🚲", key: "cycling", name: t('cycling'), description: t('cycling_description'), priority: 0},
        {id: 39, icon: "🛠️", key: "repairs", name: t('repairs'), description: t('repairs_description'), priority: 0},
        {id: 40, icon: "👶", key: "childcare", name: t('childcare'), description: t('childcare_description'), priority: 0},
        {id: 41, icon: "👴", key: "eldercare", name: t('eldercare'), description: t('eldercare_description'), priority: 0},
        {id: 42, icon: "🚤", key: "boating", name: t('boating'), description: t('boating_description'), priority: 0},
        {id: 43, icon: "✂️", key: "beauty", name: t('beauty'), description: t('beauty_description'), priority: 0},
        {id: 44, icon: "📱", key: "mobile", name: t('mobile'), description: t('mobile_description'), priority: 0},
        {id: 45, icon: "📺", key: "streaming", name: t('streaming'), description: t('streaming_description'), priority: 0},
        {id: 46, icon: "🧳", key: "business_travel", name: t('business_travel'), description: t('business_travel_description'), priority: 0},
        {id: 47, icon: "🗂️", key: "insurance", name: t('insurance'), description: t('insurance_description'), priority: 0},
        {id: 48, icon: "🎲", key: "lottery", name: t('lottery'), description: t('lottery_description'), priority: 0},
        {id: 49, icon: "🛡️", key: "savings", name: t('savings'), description: t('savings_description'), priority: 0},
        {id: 50, icon: "🩺", key: "therapy", name: t('therapy'), description: t('therapy_description'), priority: 0},
        {id: 51, icon: "💄", key: "makeup", name: t('makeup'), description: t('makeup_description'), priority: 0},
        {id: 52, icon: "👗", key: "fashion", name: t('fashion'), description: t('fashion_description'), priority: 0},
        {id: 53, icon: "👜", key: "accessories", name: t('accessories'), description: t('accessories_description'), priority: 0},
        {id: 54, icon: "💅", key: "nails", name: t('nails'), description: t('nails_description'), priority: 0},
        {id: 55, icon: "👠", key: "shoes", name: t('shoes'), description: t('shoes_description'), priority: 0},
        {id: 56, icon: "🛀", key: "spa", name: t('spa'), description: t('spa_description'), priority: 0},
        {id: 57, icon: "💍", key: "jewelry", name: t('jewelry'), description: t('jewelry_description'), priority: 0},
        {id: 58, icon: "📿", key: "selfcare", name: t('selfcare'), description: t('selfcare_description'), priority: 0},
        {id: 59, icon: "🩲", key: "lingerie", name: t('lingerie'), description: t('lingerie_description'), priority: 0},
        {id: 60, icon: "🧴", key: "skincare", name: t('skincare'), description: t('skincare_description'), priority: 0},
    ];

    const handleSelect = (type) => {
        setSelectedType(type);
        onSelectCategory(type);
    };

    return (
        <div className="transaction-type-container">
            <div className="transaction-type-list-wrapper">
                <ul className="transaction-type-list">
                    {transactionTypes.map((type) => (
                        <li
                            key={type.id}
                            className={`transaction-type-item ${
                                selectedType?.id === type.id ? "selected" : ""
                            }`}
                            onClick={() => handleSelect(type)}
                        >
                            <span className="icon">{type.icon}</span>
                            <span className="name">{type.name}</span>
                            <span className="description">{type.description}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default TransactionType;


