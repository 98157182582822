import React from 'react';
import {useNavigate} from 'react-router-dom';
import ButtonOne from "../../components/buttons/one/ButtonOne";
import InputOne from "../../components/input/one/InputOne";
import LoginRegisterModal from "../../components/modal/LoginRegisterModal";
import {LoginScreenScript} from './LoginScreenScript';
import {useTranslation} from "react-i18next";
import './LoginScreenPC.css';

const LoginScreenPC = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const {
        email,
        setEmail,
        password,
        setPassword,
        errorMessage,
        setErrorMessage,
        handleLogin,
    } = LoginScreenScript();

    return (
        <div className="loginScreenContainer-pc">
            <img
                src={`${process.env.PUBLIC_URL}/loginscreen1.png`}
                alt="logo"
                className="loginScreenImg-pc"
            />

            <div className="loginScreenForm-pc">
                <p className="loginScreenTitle-pc">{t('welcome')}</p>
                <p className="loginScreenIntro-pc">{t('login_intro')}</p>

                <InputOne
                    icon="faEnvelope"
                    label="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <InputOne
                    icon="faLock"
                    label="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                {errorMessage && <LoginRegisterModal message={errorMessage} onClose={() => setErrorMessage('')}/>}

                <ButtonOne title="Login" onClick={handleLogin}/>

                <div className="loginScreenRegisterContainer-pc">
                    <p className="loginScreenRegisterText-pc">{t('no_account')}</p>
                    <p className="loginScreenRegisterButton-pc" onClick={() => navigate('/register')}>{t('sign_up')}</p>
                </div>
            </div>
        </div>
    );
};

export default LoginScreenPC;
