import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import {useTranslation} from "react-i18next";
Chart.register(...registerables);

const StatisticsChart = () => {
    const {t} = useTranslation();
    const [chartData, setChartData] = useState({ labels: [], datasets: [] });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    console.error("No token found. Please log in again.");
                    return;
                }

                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/statistic/chart`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });

                if (!response.ok) {
                    console.error("Failed to fetch chart data.");
                    return;
                }

                const data = await response.json();

                const labels = data.map((item) => item.date);
                const totals = data.map((item) => parseFloat(item.total));

                setChartData({
                    labels,
                    datasets: [
                        {
                            label: t('transactions'),
                            data: totals,
                            fill: true,
                            borderColor: "#ffffff",
                            backgroundColor: "rgba(255, 255, 255, 0.2)",
                            pointBackgroundColor: "#FF006E",
                        },
                    ],
                });
            } catch (error) {
                console.error("Error fetching chart data:", error);
            }
        };

        fetchData();
    }, []);

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: { display: false },
        },
        scales: {
            x: { ticks: { color: "#ffffff" }, grid: { display: false } },
            y: { display: false, ticks: { display: false }, grid: { display: false } },
        },
    };

    return (
        <div
            style={{
                background: "linear-gradient(145deg, #5bbd96, #378b6b)",
                borderRadius: "8px",
                marginTop: "30px",
                padding: "0px",
                color: "#ffffff",
                width: "90%",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
            }}
        >
            <h2 style={{ fontSize: "18px", fontWeight: "bold", textAlign: "center" }}>
                {t('overview')}
            </h2>
            <div style={{ height: "200px", margin: "20px 0" }}>
                <Line data={chartData} options={options} />
            </div>
        </div>
    );
};

export default StatisticsChart;
