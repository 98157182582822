import { useState, useEffect } from 'react';

export const useUserName = (token) => {
    const [userName, setUserName] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserName = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/profile/name`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const data = await response.json();

                if (response.ok) {
                    setUserName(data.name);
                } else {
                    setError(data.error || 'Failed to fetch user name');
                }
            } catch (error) {
                setError('Network error occurred');
            } finally {
                setLoading(false);
            }
        };

        if (token) {
            fetchUserName();
        }
    }, [token]);

    return { userName, loading, error };
};
