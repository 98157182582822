import React from "react";
import {useBalance} from "./BalanceCardScript";
import "./BalanceCardMB.css";
import {useTranslation} from 'react-i18next';

const BalanceCardMB = () => {
    const token = localStorage.getItem('token');
    const {balance, loading, error} = useBalance(token);
    const {t} = useTranslation();

    return (
        <div className="balance-card-mb">
            <div className="background-shape-1-mb"></div>
            <div className="background-shape-2-mb"></div>
            <div className="background-shape-3-mb"></div>

            <div className="balance-card-content-mb">
                <p className="card-title-mb">{t('total_balance')}</p>
                <div className="balance-amount-mb">
                    <h1>
                        {loading
                            ? t('loading')
                            : error
                                ? t('error')
                                : balance.net_total.toLocaleString('en-US')} MDL
                    </h1>
                </div>
            </div>
        </div>
    );
};

export default BalanceCardMB;
