import React from "react";
import {NavLink} from "react-router-dom";
import {FiGrid, FiUser, FiPlus} from "react-icons/fi";
import "./BottomMenuPC.css";
import {useTranslation} from 'react-i18next';

const BottomMenuPC = () => {
    const {t} = useTranslation();

    return (
        <div className="vertical-menu-pc">
            <NavLink
                to="/home/dashboard"
                className={({isActive}) => `vertical-menu-item-pc ${isActive ? "active" : ""}`}
            >
                <FiGrid className="vertical-menu-icon-pc"/>
                <span>{t('dashboard')}</span>
            </NavLink>

            <NavLink
                to="/home/add"
                className={({isActive}) => `vertical-menu-item-pc ${isActive ? "active" : ""}`}
            >
                <FiPlus className="vertical-menu-icon-pc"/>
                <span>{t('add')}</span>
            </NavLink>

            <NavLink
                to="/home/profile"
                className={({isActive}) => `vertical-menu-item-pc ${isActive ? "active" : ""}`}
            >
                <FiUser className="vertical-menu-icon-pc"/>
                <span>{t('profile')}</span>
            </NavLink>
        </div>
    );
};

export default BottomMenuPC;
