import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    welcome: "Welcome back!",
                    login_intro: "Welcome back! Log in to continue your experience with us.",
                    email: "Email",
                    password: "Password",
                    login: "Login",
                    no_account: "Don’t have an account?",
                    sign_up: "Sign Up",
                    create_account: "Create an account!",
                    register_intro: "Join us! Create an account to start a new experience.",
                    username: "Username",
                    already_have_account: "Have an account?",
                    sign_in: "Sign In",
                    invalid_login_credentials: "The login details you entered are incorrect.",
                    invalid_register_credentials: "Please check your details. The email may not exist, or the login or password is incorrect.",
                    total_balance: "Total Balance",
                    dashboard: "Dashboard",
                    add: "Add",
                    profile: "Profile",
                    log_out: "Log Out",
                    confirm_log_out: "Are you sure you want to log out?",
                    confirmation: "Confirmation",
                    confirm: "Confirm",
                    cancel: "Cancel",
                    ops: "Ops !",
                    success: "Success",
                    failed: "Failed",
                    no_category_selected: "No category selected",
                    invalid_amount: "Enter a valid amount",
                    no_authentication_token: "No authentication token found. Please log in.",
                    transaction_saved_successfully: "Transaction saved successfully!",
                    failed_to_save_transaction: "Failed to save transaction",
                    network_error: "An error occurred while saving the transaction. Please try again.",
                    unknown_error: "Unknown error",
                    enter_amount: "Enter amount",
                    error: "Error",
                    loading: "Loading...",
                    income: "Income",
                    expens: "Expense",
                    delete_transaction: "Are you sure you want to delete this transaction?",
                    delete: "Delete",
                    transactions: "Transactions",
                    overview: "Overview",

                    dining_cooking: "Dining & Cooking",
                    dining_cooking_description: "Meals, canteens or cooking",
                    restaurant: "Restaurant",
                    restaurant_description: "Meals at restaurants",
                    cafe: "Cafe",
                    cafe_description: "Coffee and snacks",
                    sweets: "Sweets & Chocolate",
                    sweets_description: "Sweets or desserts",
                    drinks: "Drinks",
                    drinks_description: "Water, juices, alcohol",
                    public_transport: "Public Transport",
                    public_transport_description: "Bus, metro, and trains",
                    taxi_transport: "Taxi Transport",
                    taxi_transport_description: "Taxi and ride-sharing",
                    cinema: "Cinema",
                    cinema_description: "Movies and theaters",
                    gym: "Gym",
                    gym_description: "Strength training and workouts",
                    boxing: "Boxing",
                    boxing_description: "Boxing classes or gear",
                    swimming: "Swimming",
                    swimming_description: "Pool fees or lessons",
                    office_work: "Office/Physical Work",
                    office_work_description: "Office supplies or manual labor",
                    gifts: "Gifts",
                    gifts_description: "Presents for friends or relatives",
                    love_dating: "Love & Dating",
                    love_dating_description: "Romantic gestures or outings",
                    health_medicine: "Health & Medicine",
                    health_medicine_description: "Medical bills or medications",
                    personal_car: "Personal Car",
                    personal_car_description: "Maintenance, repairs, or fuel",
                    shopping: "Shopping",
                    shopping_description: "Clothing, perfumes, cosmetics",
                    learning: "Learning",
                    learning_description: "Courses and skill upgrades",
                    home_care: "Home Care",
                    home_care_description: "Cleaning and maintenance",
                    utilities: "Utilities",
                    utilities_description: "Electricity, water, internet bills",
                    loans: "Loans & Credits",
                    loans_description: "Loan payments or borrowing",
                    music: "Music",
                    music_description: "Instruments, subscriptions",
                    art: "Art",
                    art_description: "Painting, supplies",
                    photography: "Photography",
                    photography_description: "Camera gear, printing",
                    travel: "Travel",
                    travel_description: "Flights, accommodations",
                    vacation: "Vacation",
                    vacation_description: "Holiday expenses",
                    pets: "Pets",
                    pets_description: "Pet food, vet care",
                    groceries: "Groceries",
                    groceries_description: "Daily essentials",
                    clothing: "Clothing",
                    clothing_description: "Apparel and footwear",
                    electronics: "Electronics",
                    electronics_description: "Gadgets and devices",
                    sports: "Sports",
                    sports_description: "Sporting goods, activities",
                    education: "Education",
                    education_description: "Courses and tuition",
                    books: "Books",
                    books_description: "Literature and textbooks",
                    internet: "Internet",
                    internet_description: "ISP bills and services",
                    rent: "Rent",
                    rent_description: "Monthly rent payments",
                    gardening: "Gardening",
                    gardening_description: "Plants and tools",
                    gaming: "Gaming",
                    gaming_description: "Games and consoles",
                    cycling: "Cycling",
                    cycling_description: "Bikes and accessories",
                    repairs: "Repairs",
                    repairs_description: "Home or vehicle repairs",
                    childcare: "Childcare",
                    childcare_description: "Babysitting, supplies",
                    eldercare: "Eldercare",
                    eldercare_description: "Care for elderly",
                    boating: "Boating",
                    boating_description: "Boat rentals, fuel",
                    beauty: "Beauty",
                    beauty_description: "Haircuts, cosmetics",
                    mobile: "Mobile",
                    mobile_description: "Phone bills, devices",
                    streaming: "Streaming",
                    streaming_description: "Subscriptions and services",
                    business_travel: "Business Travel",
                    business_travel_description: "Work-related travel",
                    insurance: "Insurance",
                    insurance_description: "Health and car insurance",
                    lottery: "Lottery",
                    lottery_description: "Tickets and winnings",
                    savings: "Savings",
                    savings_description: "Deposits, investments",
                    therapy: "Therapy",
                    therapy_description: "Counseling or treatment",
                    makeup: "Makeup",
                    makeup_description: "Cosmetics and beauty products",
                    fashion: "Fashion",
                    fashion_description: "Trendy outfits and styles",
                    accessories: "Accessories",
                    accessories_description: "Bags, scarves, and more",
                    nails: "Nails",
                    nails_description: "Manicures and nail art",
                    shoes: "Shoes",
                    shoes_description: "Footwear and sandals",
                    spa: "Spa",
                    spa_description: "Relaxation and wellness treatments",
                    jewelry: "Jewelry",
                    jewelry_description: "Rings, necklaces, and bracelets",
                    selfcare: "Self-care",
                    selfcare_description: "Wellness and personal care",
                    lingerie: "Lingerie",
                    lingerie_description: "Intimate apparel",
                    skincare: "Skincare",
                    skincare_description: "Face masks, creams, and serums",
                },
            },
            ro: {
                translation: {
                    welcome: "Bun venit!",
                    login_intro: "Bine ai revenit! Conectează-te pentru a continua experiența cu noi.",
                    email: "Email",
                    password: "Parolă",
                    login: "Conectare",
                    no_account: "Nu ai un cont?",
                    sign_up: "Înregistrează-te",
                    create_account: "Creează un cont!",
                    register_intro: "Alătură-te nouă! Creează un cont pentru a începe o experiență nouă.",
                    username: "Nume utilizator",
                    already_have_account: "Ai deja un cont?",
                    sign_in: "Conectează-te",
                    invalid_login_credentials: "Datele introduse pentru autentificare sunt greșite.",
                    invalid_register_credentials: "Vă rugăm să verificați datele introduse. Emailul poate să nu existe sau loginul sau parola sunt incorecte.",
                    total_balance: "Balanța Totală",
                    dashboard: "Tablou de bord",
                    add: "Adaugă",
                    profile: "Profil",
                    log_out: "Deconectare",
                    confirm_log_out: "Ești sigur că vrei să te deconectezi?",
                    confirmation: "Confirmare",
                    confirm: "Confirmă",
                    cancel: "Anulează",
                    ops: "Ups !",
                    success: "Succes",
                    failed: "Eșec",
                    no_category_selected: "Nicio categorie selectată",
                    invalid_amount: "Introduceți o sumă validă",
                    no_authentication_token: "Nu s-a găsit niciun token de autentificare. Vă rugăm să vă autentificați.",
                    transaction_saved_successfully: "Tranzacția a fost salvată cu succes!",
                    failed_to_save_transaction: "Tranzacția nu a putut fi salvată",
                    network_error: "A apărut o eroare de rețea. Vă rugăm să încercați din nou.",
                    unknown_error: "Eroare necunoscută",
                    enter_amount: "Introduceți suma",
                    error: "Eroare",
                    loading: "Se încarcă...",
                    income: "Venit",
                    expense: "Cheltuială",
                    delete_transaction: "Ești sigur că vrei să ștergi această tranzacție?",
                    delete: "Șterge",
                    transactions: "Tranzacții",
                    overview: "Prezentare generală",

                    dining_cooking: "Masă & Gătit",
                    dining_cooking_description: "Mese, cantine sau gătit",
                    restaurant: "Restaurant",
                    restaurant_description: "Mese la restaurante",
                    cafe: "Cafenea",
                    cafe_description: "Cafea și gustări",
                    sweets: "Dulciuri & Ciocolată",
                    sweets_description: "Dulciuri sau deserturi",
                    drinks: "Băuturi",
                    drinks_description: "Apă, sucuri, alcool",
                    public_transport: "Transport Public",
                    public_transport_description: "Autobuz, metrou, trenuri",
                    taxi_transport: "Transport cu Taxiul",
                    taxi_transport_description: "Taxi și servicii ride-sharing",
                    cinema: "Cinema",
                    cinema_description: "Filme și teatre",
                    gym: "Sală de Forță",
                    gym_description: "Antrenamente și forță",
                    boxing: "Box",
                    boxing_description: "Cursuri de box sau echipament",
                    swimming: "Înot",
                    swimming_description: "Taxe piscină sau lecții",
                    office_work: "Lucru la birou sau fizic",
                    office_work_description: "Materiale de birou sau muncă fizică",
                    gifts: "Cadouri",
                    gifts_description: "Cadouri pentru prieteni sau rude",
                    love_dating: "Dragoste & Întâlniri",
                    love_dating_description: "Gesturi romantice sau întâlniri",
                    health_medicine: "Sănătate & Medicamente",
                    health_medicine_description: "Cheltuieli medicale sau medicamente",
                    personal_car: "Mașină Personală",
                    personal_car_description: "Întreținere, reparații sau combustibil",
                    shopping: "Cumpărături",
                    shopping_description: "Îmbrăcăminte, parfumuri, produse cosmetice",
                    learning: "Învățare",
                    learning_description: "Cursuri și perfecționare a abilităților",
                    home_care: "Îngrijire casă",
                    home_care_description: "Curățenie și întreținere",
                    utilities: "Utilități",
                    utilities_description: "Facturi la electricitate, apă, internet",
                    loans: "Credite și împrumuturi",
                    loans_description: "Plăți pentru credite sau împrumuturi",
                    music: "Muzică",
                    music_description: "Instrumente, abonamente",
                    art: "Artă",
                    art_description: "Pictură, materiale",
                    photography: "Fotografie",
                    photography_description: "Aparatură, printare",
                    travel: "Călătorii",
                    travel_description: "Zboruri, cazări",
                    vacation: "Vacanță",
                    vacation_description: "Cheltuieli de vacanță",
                    pets: "Animale de companie",
                    pets_description: "Hrană, îngrijire veterinară",
                    groceries: "Produse alimentare",
                    groceries_description: "Esențiale zilnice",
                    clothing: "Îmbrăcăminte",
                    clothing_description: "Haine și încălțăminte",
                    electronics: "Electronice",
                    electronics_description: "Gadgeturi și dispozitive",
                    sports: "Sporturi",
                    sports_description: "Echipamente sportive, activități",
                    education: "Educație",
                    education_description: "Cursuri și taxe școlare",
                    books: "Cărți",
                    books_description: "Literatură și manuale",
                    internet: "Internet",
                    internet_description: "Facturi ISP și servicii",
                    rent: "Chirie",
                    rent_description: "Plăți lunare pentru chirie",
                    gardening: "Grădinărit",
                    gardening_description: "Plante și unelte",
                    gaming: "Gaming",
                    gaming_description: "Jocuri și console",
                    cycling: "Ciclism",
                    cycling_description: "Biciclete și accesorii",
                    repairs: "Reparații",
                    repairs_description: "Reparații casă sau auto",
                    childcare: "Îngrijire copii",
                    childcare_description: "Bone, produse pentru copii",
                    eldercare: "Îngrijire bătrâni",
                    eldercare_description: "Îngrijire pentru bătrâni",
                    boating: "Navigație",
                    boating_description: "Închirieri bărci, combustibil",
                    beauty: "Frumusețe",
                    beauty_description: "Tunsori, cosmetice",
                    mobile: "Telefonie mobilă",
                    mobile_description: "Facturi telefon, dispozitive",
                    streaming: "Streaming",
                    streaming_description: "Abonamente și servicii",
                    business_travel: "Călătorii de afaceri",
                    business_travel_description: "Călătorii în interes de serviciu",
                    insurance: "Asigurări",
                    insurance_description: "Asigurări de sănătate și auto",
                    lottery: "Loterie",
                    lottery_description: "Bilete și câștiguri",
                    savings: "Economii",
                    savings_description: "Depozite, investiții",
                    therapy: "Terapie",
                    therapy_description: "Consiliere sau tratament",
                    makeup: "Machiaj",
                    makeup_description: "Produse cosmetice și de frumusețe",
                    fashion: "Modă",
                    fashion_description: "Ținute și stiluri la modă",
                    accessories: "Accesorii",
                    accessories_description: "Genți, eșarfe și altele",
                    nails: "Unghii",
                    nails_description: "Manichiuri și artă pentru unghii",
                    shoes: "Încălțăminte",
                    shoes_description: "Pantofi și sandale",
                    spa: "Spa",
                    spa_description: "Relaxare și tratamente de wellness",
                    jewelry: "Bijuterii",
                    jewelry_description: "Inele, coliere și brățări",
                    selfcare: "Îngrijire personală",
                    selfcare_description: "Wellness și auto-îngrijire",
                    lingerie: "Lenjerie",
                    lingerie_description: "Îmbrăcăminte intimă",
                    skincare: "Îngrijire a pielii",
                    skincare_description: "Măști faciale, creme și seruri"
                },
            },
            ru: {
                translation: {
                    welcome: "Добро пожаловать!",
                    login_intro: "Добро пожаловать! Войдите, чтобы продолжить пользоваться нашими услугами.",
                    email: "Электронная почта",
                    password: "Пароль",
                    login: "Войти",
                    no_account: "Нет аккаунта?",
                    sign_up: "Зарегистрироваться",
                    create_account: "Создайте аккаунт!",
                    register_intro: "Присоединяйтесь к нам! Создайте аккаунт, чтобы начать новый опыт.",
                    username: "Имя пользователя",
                    already_have_account: "У вас уже есть аккаунт?",
                    sign_in: "Войти",
                    invalid_login_credentials: "Введенные вами данные для входа неверны.",
                    invalid_register_credentials: "Проверьте свои данные. Электронная почта может не существовать, или логин или пароль неверны.",
                    total_balance: "Общий Баланс",
                    dashboard: "Панель управления",
                    add: "Добавить",
                    profile: "Профиль",
                    log_out: "Выйти",
                    confirm_log_out: "Вы уверены, что хотите выйти?",
                    confirmation: "Подтверждение",
                    confirm: "Подтвердить",
                    cancel: "Отмена",
                    ops: "Упс !",
                    success: "Успех",
                    failed: "Неудача",
                    no_category_selected: "Категория не выбрана",
                    invalid_amount: "Введите допустимую сумму",
                    no_authentication_token: "Токен аутентификации не найден. Пожалуйста, войдите в систему.",
                    transaction_saved_successfully: "Транзакция успешно сохранена!",
                    failed_to_save_transaction: "Не удалось сохранить транзакцию",
                    network_error: "Произошла ошибка сети. Пожалуйста, попробуйте еще раз.",
                    unknown_error: "Неизвестная ошибка",
                    enter_amount: "Введите сумму",
                    error: "Ошибка",
                    loading: "Загрузка...",
                    income: "Доход",
                    expense: "Расход",
                    delete_transaction: "Вы уверены, что хотите удалить эту транзакцию?",
                    delete: "Удалить",
                    transactions: "Транзакции",
                    overview: "Обзор",

                    dining_cooking: "Обеды и Готовка",
                    dining_cooking_description: "Еда, столовой или готовка",
                    restaurant: "Ресторан",
                    restaurant_description: "Еда в ресторанах",
                    cafe: "Кафе",
                    cafe_description: "Кофе и закуски",
                    sweets: "Сладости и Шоколад",
                    sweets_description: "Сладости или десерты",
                    drinks: "Напитки",
                    drinks_description: "Вода, соки, алкоголь",
                    public_transport: "Общественный Транспорт",
                    public_transport_description: "Автобусы, метро, поезда",
                    taxi_transport: "Такси",
                    taxi_transport_description: "Такси и сервисы каршеринга",
                    cinema: "Кино",
                    cinema_description: "Фильмы и театры",
                    gym: "Тренажерный зал",
                    gym_description: "Тренировки и сила",
                    boxing: "Бокс",
                    boxing_description: "Уроки бокса или оборудование",
                    swimming: "Плавание",
                    swimming_description: "Абонементы на бассейн или уроки",
                    office_work: "Офисная или физическая работа",
                    office_work_description: "Канцелярия или ручной труд",
                    gifts: "Подарки",
                    gifts_description: "Подарки для друзей или родственников",
                    love_dating: "Любовь и Свидания",
                    love_dating_description: "Романтические жесты или свидания",
                    health_medicine: "Здоровье и Лекарства",
                    health_medicine_description: "Медицинские счета или лекарства",
                    personal_car: "Личный Автомобиль",
                    personal_car_description: "Обслуживание, ремонт или топливо",
                    shopping: "Покупки",
                    shopping_description: "Одежда, духи, косметика",
                    learning: "Обучение",
                    learning_description: "Курсы и повышение квалификации",
                    home_care: "Уход за Домом",
                    home_care_description: "Уборка и обслуживание",
                    utilities: "Коммунальные Услуги",
                    utilities_description: "Счета за электричество, воду, интернет",
                    loans: "Кредиты и Займы",
                    loans_description: "Платежи по кредитам или займы",
                    music: "Музыка",
                    music_description: "Инструменты, подписки",
                    art: "Искусство",
                    art_description: "Живопись, материалы",
                    photography: "Фотография",
                    photography_description: "Камеры, печать",
                    travel: "Путешествия",
                    travel_description: "Авиабилеты, проживание",
                    vacation: "Отпуск",
                    vacation_description: "Расходы на отдых",
                    pets: "Домашние животные",
                    pets_description: "Корм, уход за питомцами",
                    groceries: "Продукты",
                    groceries_description: "Ежедневные нужды",
                    clothing: "Одежда",
                    clothing_description: "Одежда и обувь",
                    electronics: "Электроника",
                    electronics_description: "Гаджеты и устройства",
                    sports: "Спорт",
                    sports_description: "Спортивный инвентарь, занятия",
                    education: "Образование",
                    education_description: "Курсы и обучение",
                    books: "Книги",
                    books_description: "Литература и учебники",
                    internet: "Интернет",
                    internet_description: "Счета за интернет и услуги",
                    rent: "Аренда",
                    rent_description: "Ежемесячная оплата аренды",
                    gardening: "Садоводство",
                    gardening_description: "Растения и инструменты",
                    gaming: "Игры",
                    gaming_description: "Игры и консоли",
                    cycling: "Велоспорт",
                    cycling_description: "Велосипеды и аксессуары",
                    repairs: "Ремонт",
                    repairs_description: "Ремонт дома или автомобиля",
                    childcare: "Уход за детьми",
                    childcare_description: "Няни, детские товары",
                    eldercare: "Уход за пожилыми",
                    eldercare_description: "Уход за пожилыми людьми",
                    boating: "Лодки",
                    boating_description: "Аренда лодок, топливо",
                    beauty: "Красота",
                    beauty_description: "Стрижки, косметика",
                    mobile: "Мобильная связь",
                    mobile_description: "Счета за телефон, устройства",
                    streaming: "Стриминг",
                    streaming_description: "Подписки и услуги",
                    business_travel: "Деловые поездки",
                    business_travel_description: "Рабочие командировки",
                    insurance: "Страхование",
                    insurance_description: "Медицинская и автомобильная страховка",
                    lottery: "Лотерея",
                    lottery_description: "Билеты и выигрыши",
                    savings: "Сбережения",
                    savings_description: "Депозиты, инвестиции",
                    therapy: "Терапия",
                    therapy_description: "Консультации или лечение",
                    makeup: "Макияж",
                    makeup_description: "Косметика и средства красоты",
                    fashion: "Мода",
                    fashion_description: "Модные наряды и стили",
                    accessories: "Аксессуары",
                    accessories_description: "Сумки, шарфы и другое",
                    nails: "Ногти",
                    nails_description: "Маникюр и нейл-арт",
                    shoes: "Обувь",
                    shoes_description: "Туфли и сандалии",
                    spa: "Спа",
                    spa_description: "Расслабление и велнес-процедуры",
                    jewelry: "Ювелирные изделия",
                    jewelry_description: "Кольца, ожерелья и браслеты",
                    selfcare: "Самообслуживание",
                    selfcare_description: "Велнес и уход за собой",
                    lingerie: "Нижнее белье",
                    lingerie_description: "Интимная одежда",
                    skincare: "Уход за кожей",
                    skincare_description: "Маски для лица, кремы и сыворотки"
                },
            },
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;

