import React from 'react';
import { useUserName } from './TopMenuScript';
import "./TopMenuPC.css";

const TopMenuPC = () => {
    const token = localStorage.getItem('token');
    const { userName, loading, error } = useUserName(token);
    const appName = "Tenger";
    const appImage = "/logo192.png";
    const userImage = "/user-icon.png";

    return (
        <div className="top-menu-container-pc">
            <div className="top-menu-left-section-pc">
                <img src={appImage} alt="App Logo" className="top-menu-app-image-pc" />
                <span className="top-menu-app-name-pc">{appName}</span>
            </div>

            <div className="top-menu-right-section-pc">
                <img src={userImage} alt="User" className="top-menu-user-image-pc" />
                <span className="top-menu-user-name-pc">
                    {loading ? 'Loading...' : error ? 'Error' : userName}
                </span>
            </div>
        </div>
    );
};

export default TopMenuPC;
