import React from "react";
import { FiUser, FiGrid, FiPlus } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import "./BottomMenuMB.css";
import {useTranslation} from 'react-i18next';

const BottomMenuMB = ({ currentPath }) => {
    const {t} = useTranslation();

    return (
        <div className="bottom-menu-mb">
            <NavLink
                to="/home/dashboard"
                className={({ isActive }) => `menu-item-mb ${isActive ? "active" : ""}`}
            >
                <FiGrid className="bottom-icon-mb" />
                <span>{t('dashboard')}</span>
            </NavLink>

            <NavLink
                to="/home/add"
                className={`menu-center-mb ${currentPath === "/home/add" ? "active" : ""}`}
            >
                <FiPlus className="center-icon-mb" />
            </NavLink>

            <NavLink
                to="/home/profile"
                className={({ isActive }) => `menu-item-mb ${isActive ? "active" : ""}`}
            >
                <FiUser className="bottom-icon-mb" />
                <span>{t('profile')}</span>
            </NavLink>
        </div>
    );
};

export default BottomMenuMB;
