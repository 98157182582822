import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './InputOne.css';
import {faEnvelope, faLock, faUser, faTag, faDollarSign, faMoneyBill} from '@fortawesome/free-solid-svg-icons';

const iconMap = {
    faEnvelope: faEnvelope,
    faLock: faLock,
    faUser: faUser,
    faTag: faTag,
    faDollarSign: faDollarSign,
    faMoneyBill: faMoneyBill,
};

function InputOne({icon, label, value, onChange, type = 'text'}) {
    return (
        <div className="inputTextContainer">
            <FontAwesomeIcon icon={iconMap[icon]} className="inputTextIcon"/>
            <input
                type={type}
                className="inputTextInput"
                placeholder={label}
                value={value}
                onChange={onChange}
            />
        </div>
    );
}

export default InputOne;
