import React, { useEffect } from "react";
import "./Modal.css";
import {useTranslation} from "react-i18next";

function DeleteConfirmationModal({ message, onClose, onConfirm }) {
    const {t} = useTranslation();
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, [onClose]);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget && onClose) {
            onClose();
        }
    };

    return (
        <div className="modalOverlay" onClick={handleOverlayClick}>
            <div className="modalContent">
                <p className="modalTitle">{t('confirmation')}</p>
                <p className="modalMessage">{message}</p>
                <div className="modalButtonContainer">
                    <button onClick={onConfirm} className="modalButton confirmButton">
                        {t('delete')}
                    </button>
                    <button onClick={onClose} className="modalButton cancelButton">
                        {t('cancel')}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DeleteConfirmationModal;
