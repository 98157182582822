import React from "react";
import ButtonOne from "../../components/buttons/one/ButtonOne";
import { useProfileScreen } from "./ProfileScreenScript";
import LogOutModal from "../../components/modal/LogOutModal";
import "./ProfileScreenPC.css";
import {useTranslation} from 'react-i18next';

function ProfileScreenPC() {
    const {
        showModal,
        handleConfirmLogout,
        handleCloseModal,
        handleItemClick,
    } = useProfileScreen();
    const {t} = useTranslation();

    return (
        <div className="profile-screen-container-pc">
            <div className="profile-logout-button-pc">
                <ButtonOne title={t('log_out')} onClick={() => handleItemClick("log-out")} />
            </div>

            {showModal && (
                <LogOutModal
                    message={t('confirm_log_out')}
                    onClose={handleCloseModal}
                    onConfirm={handleConfirmLogout}
                />
            )}
        </div>
    );
}

export default ProfileScreenPC;
