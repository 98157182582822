import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonOne from "../../components/buttons/one/ButtonOne";
import { useNavigate } from 'react-router-dom';
import InputOne from "../../components/input/one/InputOne";
import LoginRegisterModal from "../../components/modal/LoginRegisterModal";
import { handleRegister } from "./RegisterScript";
import './RegisterScreenPC.css';

function RegisterScreenPC() {
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [email, setEmail] =useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();

    const handleSubmit = () => {
        handleRegister(name, email, password, navigate, setErrorMessage, t);
    };

    return (
        <div className="registerScreenContainer-pc">
            <img
                src={`${process.env.PUBLIC_URL}/registerscreen1.png`}
                alt="logo"
                className="registerScreenImg-pc"
            />

            <div className="registerScreenForm-pc">
                <p className="registerScreenTitle-pc">{t('create_account')}</p>
                <p className="registerScreenIntro-pc">{t('register_intro')}</p>

                <InputOne
                    icon="faUser"
                    label={t('username')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <InputOne
                    icon="faEnvelope"
                    label={t('email')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />

                <InputOne
                    icon="faLock"
                    label={t('password')}
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />

                {errorMessage && <LoginRegisterModal message={errorMessage} onClose={() => setErrorMessage('')} />}

                <ButtonOne title={t('sign_up')} onClick={handleSubmit} />

                <div className="registerScreenLoginContainer-pc">
                    <p className="registerScreenLoginText-pc">{t('already_have_account')}</p>
                    <p className="registerScreenLoginButton-pc" onClick={() => navigate('/login')}>
                        {t('sign_in')}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default RegisterScreenPC;
