import React, {useEffect} from "react";
import BalanceCardPC from "../../components/balanceCard/BalanceCardPC";
import "./DashboardScreenPC.css";
import TransactionList from "../../components/transactionList/TransactionList";
import {useDashboardScreen} from "./DashboardScreenScripts";
import StatisticsChart from "../../components/chart/bar/StatisticsChart";

function DashboardScreenPC() {
    useDashboardScreen();

    return (
        <div className="dashboard-screen-pc-conyainer">
        <div className="dashboard-screen-pc-conyainer-top">
            <BalanceCardPC/>

            <StatisticsChart/>
        </div>
            <TransactionList/>
        </div>
    );
}

export default DashboardScreenPC;
