import React, { useEffect } from "react";
import "./Modal.css";
import { useTranslation } from "react-i18next";

function NotificationModal({ message, type, onClose }) {
    const { t } = useTranslation();

    // Titlul dinamic în funcție de tip
    const title = type === "error" ? t("failed") : t("success");

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (onClose) {
                onClose();
            }
        }, 2000);

        return () => clearTimeout(timeoutId);
    }, [onClose]);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget && onClose) {
            onClose();
        }
    };

    return (
        <div className="modalOverlay" onClick={handleOverlayClick}>
            <div className={`modalContent ${type}`}>
                <p className="modalTitle">{title}</p>
                <p className="modalMessage">{message}</p>
            </div>
        </div>
    );
}

export default NotificationModal;
