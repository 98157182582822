import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import BottomMenuMB from "../../components/menu/bottom/BottomMenuMB";
import TopMenuMB from "../../components/menu/top/TopMenuMB";
import "./HomeScreenMB.css";

function HomeScreenMB() {
    const location = useLocation();

    return (
        <div className="homeScreenContainer-mb">
            <TopMenuMB/>

            <div className="homeScreenContentArea-mb">
                <Outlet/>
            </div>

            <BottomMenuMB currentPath={location.pathname}/>
        </div>
    );
}

export default HomeScreenMB;
